.Footer {
    text-align: center;
    padding: 10px 16px 10px 16px;
    margin: 0 -15px 0 -15px;
    background-color: #343a40;
    color: #ffffff;
    background-color: #007bff;
}

.FooterParagraph {
    margin: 10px 0 10px 0;
}
.navbar {
    color: #ffffff;
    background-color: #007bff;
}

.MenuTitle {
    text-decoration: underline;
}

.NavBarTitle {
    color: #ffffff !important;
}

.MainNavLinks {
    color: #ffffff !important;
    margin: 0 10px 0 10px;
}

.MainNavLinks a {
    color: #ffffff !important;
    margin: 0 10px 0 10px;
}

.MainNavLinks a:hover {
    max-width: 93%;
    color: #007bff !important;
}

.MainNavLinks div {
    background-color: #007bff;
    border-radius: 5px;
}